section.section{
	+ .section {
		margin-top: 34.5px;
	}

	&.section--space-wide{
		+ .section--space-wide {
			margin-top: 114px;
		}
	}
}
