/*----------general purpose class----------*/
//
.important{
	font-weight: 700;
	color: #f00;
}

// update
.update{
	margin-bottom: 32px;
	//
	.mark,
	.tag{
		margin-left: 0.75em;
	}
	.mark{}
	.tag{
		vertical-align: top;
		margin-top: 0.292em;
		margin-bottom: 0.292em;
	}
}


// mark
.mark{
	font-size: 1.2rem;

	&.mark--new{
		font-style: italic;
		color: #f05;
	}
}

/*-----align-----*/
.align{
	&--offset_center{
		text-align: center!important;
	}
	&--offset_right{
		text-align: right!important;
	}
	&--offset_left{
		text-align: left!important;
	}
}


/*-----float-----*/
.float{
	&--offset_right{
		float: right;
		margin-left: $base_margin;
	}
	&--offset_left{
		float: left;
		margin-right: $base_margin;
	}
}


/*-----hoverOpacity-----*/
// .hoverOpacity{
// 	text-decoration: none;
// 	vertical-align: bottom;
// 	background-color: #fff;
// 	display: inline-block;
//
// 	&:hover{
// 		text-decoration: none;
//
// 		.hoverOpacity__element{
// 			opacity:$opacity;
// 		}
// 	}
// }
// .hoverOpacity--display_block{
// 	@extend .hoverOpacity;
// 	display: block;
// }



/*-----hidden-----*/
.hidden{
	display: none!important;

	@media all and (min-width: $pc_min-width){
		&--pc{
			display: none!important;
		}
		&--mobile{}
	}

	@media all and (max-width: $landscape_width){
		&--pc{}
		&--mobile{
			display: none!important;
		}
	}
}

.hidden2{
	@media all and (min-width: $mobile_width + 1){
		&--pc{
			display: none!important;
		}
		&--mobile{}
	}

	@media all and (max-width: $mobile_width){
		&--pc{}
		&--mobile{
			display: none!important;
		}
	}
}

/*-----screen reader only-----*/
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: normal;
}


/*-----  -----*/
.hasPaddingElement {
	padding: 0 32px;

	@include mediaquery(){
		padding: 0 vwCalc(20, 375);
	}
}
