.basicDl{
	dt {
		font-size: 1.6rem;
		font-weight: bold;
		color: #fff;

		&:not(:first-child){
			margin-top: 19px;
		}
	}
	dd {
		margin-top: 9px;
	}
}
