.contentBlocks {
	//
	@mixin slit() {
		background-color: transparent;

		&::before,
		&::after {
			background: url(../images/bg_contentBlock1.svg) 50% 0 no-repeat;
			background-size: 100% auto;
			content: "";
			display: block;
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
		}
		&::before {
			top: 0;
		}
		&::after {
			bottom: 0;
			transform: scale(-1, -1);
		}
	}

	flex-grow: 1;
	display: flex;
	flex-direction: column;

	// item
	.contentBlocks__item {
		background-color: #000;
		position: relative;

		// background color
		&.contentBlocks__item--bg-darkgray {
			background-color: #1c1c1c;
		}

		//  slit
		&.contentBlocks__item--slit,
		&.contentBlocks__item--slit-reverse,
		&.contentBlocks__item--slit-top-reverse,
		&.contentBlocks__item--slit-bottom-reverse {
			@include slit();
		}
		&.contentBlocks__item--slit-reverse {
			&::before {
				transform: scale(-1, 1);
			}
			&::after {
				transform: scale(1, -1);
			}
		}
		&.contentBlocks__item--slit-top-reverse {
			&::before {
				transform: scale(-1, 1);
			}
		}
		&.contentBlocks__item--slit-bottom-reverse {
			&::after {
				transform: scale(1, -1);
			}
		}

		// slit2
		&.contentBlocks__item--slit2{
			@include slit();

			@include mediaquery("min-width: #{$pc_min-width}"){
				&::after {
					background-position: -75vw 0;
				}
			}
		}

		// grow
		&.contentBlocks__item--grow {
			flex-grow: 1;
		}

		.contentBlocks__itemInner {
			box-sizing: border-box;
			position: relative;
			z-index: 2;
			// padding: 100px ($base_margin * 4) 80px;
			padding: 100px 0 80px;
			margin: 0 auto;
			max-width: 964px;
			// max-width: 900px;

			// @include mediaquery(){
			// 	padding-right: 20px;
			// 	padding-left: 20px;
			// }

			&.contentBlocks__itemInner--v-padding80{
				padding: 80px 0;

				@include mediaquery(){
					// padding: 40px 20px;
					padding: 40px 0;
				}
			}
		}
	}


}
