.storeList{
	padding: 0 32px;

	@include mediaquery(){
		padding: 0 vwCalc(20, 375);
	}

	li {
		display: flex;
		// flex-wrap: wrap;

		@include mediaquery(){
			flex-direction: column;
		}

		+ li {
			margin-top: 11px;

			@include mediaquery() {
				margin-top: 31px;
			}
		}
	}
	.storeList__name {
		font-family: $fontfamily2;
		font-weight: bold;
		font-size: 1.6rem;
		line-height: (24 / 16);
		color: #fff;
		width: (368 / 900) * 100%;

		@include mediaquery(){
			width: auto;
		}
	}
	.storeList__phone {
		margin-left: (32 / 900) * 100%;
		width: (160 / 900) * 100%;

		@include mediaquery(){
			margin-left: 0;
			width: auto;
		}
	}
	.storeList__address {
		margin-left: (32 / 900) * 100%;
		width: (308 / 900) * 100%;

		@include mediaquery(){
			margin-left: 0;
			width: auto;
		}
	}
}
