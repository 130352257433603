.heading {
	font-family: $fontfamily2;
	font-size: 5.2rem;
	font-weight: bold;
	color: #fff;
	line-height: (63 / 52);
	display: flex;
	align-items: center;
	margin-bottom: 60px;

	@include mediaquery(){
		font-size: 3rem;
		flex-flow: column;
	}

	.heading__translation {
		font-family: $fontfamily;
		font-size: 1.5rem;
		color: #ccc;
		margin-left: $base_margin * 4;

		@include mediaquery(){
			font-size: 1.2rem;
			margin: 10px 0 0;
		}
	}
}


.heading2 {
	font-size: 3rem;
	font-weight: bold;
	line-height: (36 / 30);
	color: #fff;
	margin-bottom: 20px;

	@include mediaquery(){
		font-size: 1.5rem;
	}
}

.heading3 {
	font-size: 1.6rem;
	color: #ccc;
	margin-bottom: 17.5px;

	@include mediaquery(){
		text-align: center;
	}
}

.heading4 {
	font-size: 1.6rem;
}

.heading5 {
	font-family: $fontfamily2;
	font-size: 1.6rem;
	color: #fff;
	margin-bottom: 17.5px;
}

.heading6 {
	font-family: $fontfamily2;
	font-size: 3rem;
	font-weight: bold;
	color: #fff;
	margin-bottom: 21px;

	&::before {
		vertical-align: 0.3em;
		background-color: #fff;
		content: "";
		display: inline-block;
		margin-right: 10px;
		width: 20px;
		height: 4px;
	}

	@include mediaquery(){
		font-size: 1.6rem;
	}
}

.heading7 {
	font-family: $fontfamily2;
	font-size: 3rem;
	line-height: (36 / 30);
	color: #fff;
	text-align: center;
	margin-bottom: 17.5px;

	@include mediaquery(){
		font-size: 1.6rem;
		line-height: (24 / 16);
	}
}

.heading8 {
	font-family: $fontfamily2;
	font-size: 6.5rem;
	line-height: (79 / 65);
	text-align: center;
	color: #fff;
	margin-bottom: 54px;

	@include mediaquery(){
		font-size: 3.6rem;
		margin-bottom: 25.2px;
	}
}
