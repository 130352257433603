.historyColumn {
	overflow-x: scroll;
	margin-bottom: 36px;
	padding-left: 32px;
	max-width: 932px;

	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}

	@include mediaquery(){
		padding-left: 20px;
	}

	.historyColumn__inner{
		display: flex;
		justify-content: space-between;
		padding-right: 32px;
		width: 900px;

		@include mediaquery(){
			padding-right: 20px;
			width: 1278px;
		}
	}

	.historyColumn__col {
		width: 200px;

		@include mediaquery(){
			width: 300px;
		}
	}

	.historyColumn__thumb{
		margin-bottom: 5.5px;
	}
	.historyColumn__title {
		font-family: $fontfamily2;
		font-size: 1.6rem;
		font-weight: bold;
		line-height: (25 / 16);
		color: #fff;
	}
}
