.categorySelect{
	margin-bottom: 60px;
	padding: 0 32px;

	@include mediaquery(){
		padding: 0 vwCalc(20, 375);
	}

	.categorySelect__selector{
		font-size: 1.6rem;
		font-weight: bold;
		color: #000;
		text-align: center;
		border: none;
		border-radius: 0;
		background: url(../images/arrow.png) top 50% right 25px no-repeat #fff;
		padding: 11.5px 20px;
		width: auto;
		min-width: 335px;
		height: auto;

		@include mediaquery("max-width:" + ($mobile_width - 1)){
			width: 100%;
			min-width: 0;
		}
	}
}
