.productColumn{
	display: flex;
	justify-content: space-between;
	margin-bottom: 60px;
	padding: 0 32px;

	@include mediaquery(){
		flex-direction: column;
		align-items: center;
		padding: 0;
	}

	.productColumn__col1{
		width: (375 / 900) * 100%;

		@include mediaquery(){
			margin-bottom: 33px;
			width: 100%;
			max-width: 375px;
		}
	}
	.productColumn__col2{
		width: (465 / 900) * 100%;

		@include mediaquery(){
			box-sizing: border-box;
			padding: 0 vwCalc(20, 375);
			width: 100%;
			max-width: 375px;
		}
	}

	.productColumn__name {
		font-size: 1.6rem;
		color: #ccc;
		margin-bottom: 7px;
	}

	.productColumn__price {
		font-family: $fontfamily2;
		font-size: 1.6rem;
		font-weight: bold;
		color: #fff;
		text-align: right;
	}

	.productColumn__cart {
		text-align: right;
		margin-top: 1.846em;

		@include mediaquery(){
			text-align: center;
		}
	}
}
