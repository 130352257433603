.multiColumn {
	display: flex;
	justify-content: space-between;
	padding: 0 32px;

	@include mediaquery(){
		padding: 0;
	}

	+ .multiColumn {
		margin-top: 74.5px;

		@include mediaquery(){
			margin-top: 54.5px;
		}
	}

	@include mediaquery(){
		flex-direction: column;
		align-items: center;
	}

	&.multiColumn--row-reverse {
		flex-flow: row-reverse;

		@include mediaquery(){
			flex-direction: column;
			align-items: center;
		}
	}

	.multiColumn__col1 {
		width: (335 / 900) * 100%;

		@include mediaquery(){
			margin-bottom: 23px;
			padding: 0 vwCalc(20, 375);
			width: auto;
			max-width: 375px;
		}

		&.multiColumn__col1--sp-fluid{
			@include mediaquery(){
				padding: 0;
			}
		}
	}
	.multiColumn__col2 {
		width: (465 / 900) * 100%;

		@include mediaquery(){
			padding: 0 vwCalc(20, 375);
			width: auto;
			max-width: 375px;
		}
	}
	.multiColumn__col3 {
		width: (522 / 900) * 100%;

		@include mediaquery(){
			padding: 0 vwCalc(20, 375);
			width: auto;
			max-width: 375px;
		}
	}
}
