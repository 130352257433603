.catchphrase{
	font-family: $fontfamily2;
	font-size: 6.5rem;
	line-height: (76 / 65);
	color: #fff;
	text-align: center;
	margin-bottom: 25.2px;
	padding: 0 32px;

	@include mediaquery(){
		font-size: 4.6rem;
		padding: 0 vwCalc(20, 375);
	}

	strong{
		font-weight: normal;
	}
}


.catchphrase2 {
	font-family: $fontfamily2;
	font-size: 1.6rem;
	color: #fff;
	margin-bottom: 3.692em;
	padding: 0 32px;

	@include mediaquery(){
		margin-bottom: 21px;
		padding: 0 vwCalc(20, 375);
	}

	strong{
		font-weight: normal;
	}
}
