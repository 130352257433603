// ~sass-easing/easings
.linear{
	transition-timing-function: $linear;
}
.ease{
	transition-timing-function: $ease;
}
.ease-in{
	transition-timing-function: $ease-in;
}
.ease-out{
	transition-timing-function: $ease-out;
}
.ease-in-out{
	transition-timing-function: $ease-in-out;
}

.easeInQuad{
	transition-timing-function: $easeInQuad;
}
.easeInCubic{
	transition-timing-function: $easeInCubic;
}
.easeInQuart{
	transition-timing-function: $easeInQuart;
}
.easeInQuint{
	transition-timing-function: $easeInQuint;
}
.easeInSine{
	transition-timing-function: $easeInSine;
}
.easeInExpo{
	transition-timing-function: $easeInExpo;
}
.easeInCirc{
	transition-timing-function: $easeInCirc;
}
.easeInBack{
	transition-timing-function: $easeInBack;
}

.easeOutQuad{
	transition-timing-function: $easeOutQuad;
}
.easeOutCubic{
	transition-timing-function: $easeOutCubic;
}
.easeOutQuart{
	transition-timing-function: $easeOutQuart;
}
.easeOutQuint{
	transition-timing-function: $easeOutQuint;
}
.easeOutSine{
	transition-timing-function: $easeOutSine;
}
.easeOutExpo{
	transition-timing-function: $easeOutExpo;
}
.easeOutCirc{
	transition-timing-function: $easeOutCirc;
}
.easeOutBack{
	transition-timing-function: $easeOutBack;
}

.easeInOutQuad{
	transition-timing-function: $easeInOutQuad;
}
.easeInOutCubic{
	transition-timing-function: $easeInOutCubic;
}
.easeInOutQuart{
	transition-timing-function: $easeInOutQuart;
}
.easeInOutQuint{
	transition-timing-function: $easeInOutQuint;
}
.easeInOutSine{
	transition-timing-function: $easeInOutSine;
}
.easeInOutExpo{
	transition-timing-function: $easeInOutExpo;
}
.easeInOutCirc{
	transition-timing-function: $easeInOutCirc;
}
.easeInOutBack{
	transition-timing-function: $easeInOutBack;
}
