.movieBlock{
	text-align: center;
	margin: 0 auto 60px;
	max-width: 900px;

	.movieBlock__inner {
		position: relative;
		padding-top: 56.25%;
		width: 100%;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
