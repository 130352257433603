.productImages{
	.productImages__first-image,
	.productImages__other-images li {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		transition: opacity .3s;
		cursor: pointer;

		&:hover{
			opacity: $opacity;
		}
	}
	.productImages__first-image {
		padding-top: 100%;
	}
	.productImages__other-images {
		display: flex;
		flex-wrap: wrap;

		@include mediaquery(){
			padding: 0 20px;
		}
		@include mediaquery("max-width: 375px") {
			padding: 0 vwCalc(20, 375);
		}

		li {
			$basis_pc: 375;
			$basis_sp: 335;

			margin-top: (30 / $basis_pc) * 100%;
			margin-left: (30 / $basis_pc) * 100%;
			padding-top: (105 / $basis_pc) * 100%;
			width: (105 / $basis_pc) * 100%;

			@include mediaquery(){
				margin-top: (20 / $basis_sp) * 100%;
				margin-left: (10 / $basis_sp) * 100%;
				padding-top: (105 / $basis_sp) * 100%;
				width: (105 / $basis_sp) * 100%;
			}

			&:nth-child(3n + 1) {
				margin-left: 0;
			}
		}
	}
}

// .productImages{
// 	.productImages__first-image {
//
// 	}
// 	.productImages__other-images {
// 		display: flex;
// 		flex-wrap: wrap;
//
// 		@include mediaquery(){
// 			padding: 0 20px;
// 		}
// 		@include mediaquery("max-width: 375px") {
// 			padding: 0 vwCalc(20, 375);
// 		}
//
// 		li {
// 			margin-top: (30 / 375) * 100%;
// 			margin-left: (30 / 375) * 100%;
// 			width: (105 / 375) * 100%;
//
// 			@include mediaquery(){
// 				margin-top: (20 / 335) * 100%;
// 				margin-left: (10 / 335) * 100%;
// 				width: (105 / 335) * 100%;
// 			}
//
// 			&:nth-child(3n + 1) {
// 				margin-left: 0;
// 			}
// 		}
// 	}
// }
