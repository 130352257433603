.viewmoreList {
	display: flex;
	justify-content: space-around;
	padding: 0 32px;

	@include mediaquery(){
		flex-direction: column;
		align-items: center;
		padding: 0 vwCalc(20, 375);
	}

	li {
		@include mediaquery(){
			width: 100%;
			max-width: 330px;

			+ li {
				margin-top: 20px;
			}
		}
	}
}
