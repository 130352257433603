.figureBlock{
	margin-bottom: 34.5px;
	padding: 0 32px;

	@include mediaquery(){
		padding: 0 vwCalc(20, 375);
	}

	img{}

	figcaption{
		margin-top: 24.5px;
	}
}

.figureBlock2 {
	text-align: center;
	position: relative;
	left: 50%;
	// margin: 0 auto 1.846em;
	margin: 0 auto 30px;
	// max-width: 900px;
	width: 100vw;
	transform: translateX(-50%);

	picture {
		width: 100%;

		img {
			width: 100%;
		}
	}

	&.figureBlock2--mb2 {
		margin-bottom: 30px * 2;

		@include mediaquery(){
			margin-bottom: 30px;
		}
	}
}

.figureBlock3 {
	text-align: center;
	margin: 0 auto 30px;
	max-width: 900px;

	@include mediaquery(){
		margin-bottom: 30px;
	}
}
