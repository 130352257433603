.manualList{
	padding: 0 32px;

	@include mediaquery(){
		padding: 0 vwCalc(20, 375);
	}

	&:not(:last-child) {
		margin-bottom: 21px;
	}

	li {
		&:not(:first-child){
			margin-top: 4.5px;
		}
		a{
			font-family: $fontfamily2;
			font-size: 1.6rem;
			font-weight: bold;
			line-height: (33 / 16);
			color: #fff;
			text-decoration: none;

			&:hover {
				opacity: $opacity;
			}

			//
			.icon {
				margin-right: 18px;
			}
		}
	}
}
