@charset "utf-8";

//-----variables configuration-----
$fontfamily: "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;
$fontfamily2: "Inter", "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;


$color: #aaa;
$base_fontsize: 10px;
$default_fontsize: 16px;
$default_lineheight: (24 / 16);
$line_padding: ($default_lineheight - 1) / 2 + em;
$line_margin: round(($default_fontsize * $default_lineheight - $default_fontsize) / 2);

$mobile_width: 414px;
$portrait_width: $mobile_width;
$landscape_width: 767px;

$pc_width: 980px;
$pc_min-width: $landscape_width + 1;
$pc_max-width: 1440px;

$mediaquery_condition: "max-width:" + $landscape_width;

$theme_color: #ccc;
// $colors: (
// 	red:       (100: #a52828, 65: #c47373, 30: #e4bebe, 15: #f1dfdf),
// 	gray:      (100: #646464, 65: #9a9a9a, 30: #d0d0d0, 15: #e8e8e8),
// 	blue:      (100: #0a2d6e, 65: #6076a1, 30: #b5c0d3, 15: #dae0e9),
// 	purple:    (100: #5f143c, 65: #976680, 30: #cfb8c4, 15: #e7dce2),
// 	green:     (100: #007373, 65: #59a4a4, 30: #b2d5d5, 15: #d9eaea),
// 	orange:    (100: #d45d26, 65: #e39672, 30: #f2cebd, 15: #f9e7df),
// 	darkGreen: (100: #38612c, 65: #7d9876, 30: #c3cfbf, 15: #e1e7e0)
// );

$base_margin: 8px;
$opacity: 0.75;

$prefix: -webkit-, -moz-, -ms-, -o-, '';

$img_dir: "";


//-----functions-----
//bgPosV
//get background-postion vertical value
@function bgPosV($fontsize, $imgheight, $lineheight:$default_lineheight){
	@return (($fontsize * $lineheight - $imgheight) / 2)/$fontsize + em;
}

//retinaImg
//get image size for retina
@function retinaImg($value){
	@return round($value / 2);
}

//fitText
//inline block to fit the text
@function fitText($fontsize:$default_fontsize, $lineheight:$default_lineheight){
	@return ($fontsize * $lineheight - $fontsize) / 2;
}

//excludePrefix
@function excludePrefix($excludes){
	@if($excludes != false){
		$tmp:();
		@each $v in $prefix{
			$n:index($excludes, $v);
			@if($n==false){
				$tmp:join($tmp, $v);
			}
		}
		@return $tmp;
	}@else{
		@return $prefix;
	}
}


//stripUnit
@function stripUnit($value) {
	@return $value / ($value * 0 + 1);
}

//-----mixins-----
//joinPrefix
@mixin joinPrefix($property, $value, $excludes){
	@if($excludes != false){
		$tmp:();
		@each $v in $prefix{
			$n:index($excludes, $v);
			@if($n==false){
				$tmp:join($tmp, $v);
			}
		}
		@each $v in $tmp{
			#{$v + unquote($property)}:unquote($value);
		}
	}@else{
		@each $v in $prefix{
			#{$v + unquote($property)}:unquote($value);
		}
	}
}

//mediaquery
@mixin mediaquery($condition:$mediaquery_condition) {
	@media all and ($condition){
		@content;
	}
}

//hoverOpacity
@mixin hoverOpacity(){
	transition: opacity .25s;
	opacity: 1;

	&:hover{
		opacity: $opacity;
	}
}

//-----add-ons-----
/*clearfix*/
.clearfix{
	&::after{
		content: "";
		display: block;
		clear: both;
	}
}
@mixin clearfix(){
	&::after{
		content: "";
		display: block;
		clear: both;
	}
}

.clearfixLegacy{
	_overflow:visible;
	_height:1px;
	zoom:1;

	&:after{
		content:".";
		display:block;
		visibility:hidden;
		clear:both;
		height:0px;
		font-size:0px;
	}
}

//input text style
@mixin input_text_style(){
	// @include appearance(none);
	font-size: 1.4rem;
	border: 1px solid #e8e8e8;
	// border-radius: 4px;
	background-color: #fff;
	// @include box-shadow(1px 2px 4px rgba(0, 0, 0, .1) inset);
	box-sizing: border-box;
}

//background propaties with background-size
@mixin bg($url, $position, $repeat, $size){
	background-image:$url;
	background-position:$position;
	background-repeat:$repeat;
	background-size:$size;
}

//lineMargin
@function lineMargin($lineheight:$default_lineheight, $fontssize:$default_fontsize){
	@return round(($fontssize * $lineheight - $fontssize) / 2);
}

//vwCalc
@function vwCalc($value, $width:$landscape_width){
	$width: $width / ($width * 0 + 1);
	@return ($value / $width) * 100vw;
}

//fluidFontSize
@function fluidFontSize($min-font-size, $max-font-size, $min-vw:320, $max-vw:stripUnit($landscape_width)){
	@return calc(#{$min-font-size}px + #{$max-font-size - $min-font-size} * ((100vw - #{$min-vw}px) / #{$max-vw - $min-vw}));
}

//colors
@function colors($name, $alpha: 100){
	@return map-get(map-get($colors, $name), $alpha);
}
