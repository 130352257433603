.messageSection{
	margin: 0 auto;
	padding: 0 32px;
	max-width: 900px;

	@include mediaquery(){
		padding: 0 vwCalc(20, 375);
	}

	p {
		font-size: 2rem;
		line-height: (32 / 20);
		letter-spacing: 0.012em;
		text-align: center;

		@include mediaquery(){
			font-size: 1.6rem;
			text-align: left;
		}
	}
}
