.keyvisual{
	background-color: #000;

	// 画像
	picture {
		width: 100%;

		img {
			width: 100%;
		}
	}

	.keyvisual__inner{
		margin: 0 auto;
		// max-width: $pc_max-width;
		position: relative;
	}

	.keyvisual__logo {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		width: 265px;
		height: 60px;

		@include mediaquery(){
			width: (265px * 0.66);
			height: (60px * 0.66);
		}
		@include mediaquery("max-width: 413px"){
			width: (265px * 0.5);
			height: (60px * 0.5);
		}
	}

	// 動画
	&.keyvisual--video {
		video {
			display: block;
			margin: 0 auto;
			width: 100%;
			max-width: none;

			// @include mediaquery("max-width: 413px"){
			//   position: absolute;
			//   left: 50%;
			//   width: vwCalc(720, 375);
			//   height: vwCalc(300, 375);
			//   transform: translateX(-50%);
			// }
		}

		.keyvisual__inner{
			// @include mediaquery("max-width: 413px"){
			//   overflow: hidden;
			//   height: vwCalc(300, 375);
			// }
		}
	}
}
