.productSpec {
	display: flex;
	padding: 0 32px;

	@include mediaquery {
		flex-direction: column;
		padding: 0 vwCalc(20, 375);
	}

	.productSpec__model {
		font-family: $fontfamily2;
		font-size: 1.6rem;
		font-weight: bold;
		color: #fff;
		margin: 0 ((60 / 900) * 100%) 20px 0;
	}

	.productSpec__spec {
		flex-grow: 1;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.productSpec__table {
		// + .productSpec__table {
		// 	margin-left: ((60 / 900) * 100%);
		// }
		th {
			font-weight: normal;
			color: $color;
			vertical-align: top;
			padding-right: 15px;
			width: 120px;
		}
		td {
			width: 200px;
		}
	}
}
