.showRoom{
	padding: 0 32px;

	@include mediaquery(){
		padding: 0 vwCalc(20, 375);
	}

	.showRoom__map{
		margin-bottom: 60px;
	}

	.showRoom__column {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: -34.5px;

		@include mediaquery(){
			flex-direction: column;
			align-items: center;
			margin-top: 0;
		}

		.showRoom__col {
			width: (440 / 900) * 100%;
			margin-top: 34.5px;

			@include mediaquery(){
				margin-top: 0;
				width: 100%;
				max-width: 440px;

				&:not(:first-child) {
					margin-top: 14.5px;
				}
			}
		}
	}

	.showRoom__thumb {
		margin-bottom: 4.5px;
	}
	.showRoom__name {
		font-family: $fontfamily2;
		font-size: 1.6rem;
		font-weight: bold;
		color: #fff;

		.showRoom__translation {
			font-family: $fontfamily;
			font-size: 1.3rem;
			font-weight: normal;
			color: #ccc;
		}
	}


}
