@charset "utf-8";

/*--------------------basic settings---------------------*/
html{
	font-size: $base_fontsize;
}

body{
	font-family: $fontfamily;
	font-size: 1.6rem;
	color: $color;
	line-height: $default_lineheight;
	text-align: left;
	background: url(../images/bg_body.png) 50% 0 repeat #222;
	margin: 0 auto;
	height: 100%;
}


/*-----heading-----*/
h1, h2, h3, h4, h5, h6{
	font-size: 1.6rem;
	font-weight: 400;
	margin: 0;
}
h1, h2, h3, h4, h5, h6, dt, label{}

/*-----paragraph-----*/
p{
	margin: 0 0 1.846em;

	&:last-child {
		margin-bottom: 0;
	}
}

/*-----lists-----*/
ul, ol, dl, dl dt, dl dd{
	list-style: none;
	margin: 0;
	padding: 0;
}
ul, ol{
	li{}
}

dl{
	dt{
		font-weight: 700;
	}
}


/*-----table-----*/
table{
	border-collapse: separate;
	border-spacing: 0;
}

/*-----horizontal line-----*/
hr{
	border: 0;
	border-top: 1px solid #ccc;
	display: block;
	margin: 20px 0 0;
	padding: 0;
	height: 0;
}

/*-----quote-----*/
blockquote, q{
	quotes: none;

	&:before, &:after{
		content: '';
		content: none;
	}
}
blockquote{
	margin: 1.5em 1em;
}

/*-----anchor-----*/
a{
	color: $theme_color;
	text-decoration: underline;
	transition: all .3s;

	// &:visited{
	// }
	&:hover{
		text-decoration: none;
	}
	&:active{}

	// &[target="_blank"]:not(.no-icon){
	//
	// 	&::after{
	// 		vertical-align: 0;
	// 		background: url(#{$img_dir}icons/newwindow.png) 0 0 no-repeat;
	// 		content: "";
	// 		display: inline-block;
	// 		margin-left: 0.5em;
	// 		width: 16px;
	// 		height: 16px;
	// 	}
	// }

// print
// @media print{
// 	&[target="_blank"]:not(.no-icon){
// 		&::after{
// 			content: none;
// 		}
// 	}
// }
}

/*-----strong/emphasis-----*/
strong, b{
	font-weight: 700;
}
em{
	font-style: normal;
}

/*-----mark-----
mark{
	background-color: #ff9;
	font-style: italic;
	font-weight: 700;
	color: #000;
}*/

/*-----insert/delete-----
ins{
	background-color: #ff9;
	color: #000;
	text-decoration: none;
}
del{ text-decoration: line-through;}*/

/*-----abbreviation/acronym/definition-----*/
abbr[title], acronym[title], dfn[title]{
	text-decoration: underline;
	border-bottom: 0;
	cursor: help;
}

/*-----preformatted text-----*/
code, kbd, pre, samp {
	font-size: 1.6rem;
}
pre{
	// border: 1px solid #e0e0e0;
	background-color: #f2f2f2;
	box-sizing: border-box;
	margin: 0;
	padding: fitText() 2em;
}

/*-----small-----*/
small{
	font-size: 1.6rem;
}

/*-----address-----*/
address{
	font-style: normal;
}

/*-----image-----*/
img{
	font-family: sans-serif;
	line-height: 1;
	vertical-align: bottom;
	border: none;
	// -ms-interpolation-mode: bicubic;
	max-width: 100%;
}

/*-----figure-----*/
figure{
	margin: 0;
}


/*-----picture-----*/
picture{
	line-height: 0;
	vertical-align: top;
	display: inline-block;
}


/*-----media-----*/
embed,
iframe,
object,
video{
	max-width: 100%;
}

/*-----form parts-----*/
form {
	margin: 0;
}

fieldset{
	border: 0;
	margin: 0;
	padding: 0;
}

label{}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="checkbox"],
input[type="radio"],
input[type="submit"],
input[type="image"],
input[type="button"],
textarea,
select{
	font-family: $fontfamily;
	font-size: 1.4rem;
	line-height: $default_lineheight;
	vertical-align: middle;
	margin: fitText() 0;
}

input[type="text"],
textarea{
	// padding: 0.167em 8px;
}

input[type="file"]{
	font: static;
}

select{
	appearance: none;
}
