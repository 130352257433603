.btn {
	text-decoration: none;

	&.btn--addcart {
		font-size: 1.6rem;
		color: #000;
		line-height: (24 / 16);
		text-align: center;
		border: none;
		border-radius: 0;
		box-sizing: border-box;
		background-color: #fff;
		position: relative;
		padding-top: 14px;
		padding-bottom: 14px;
		width: 335px;
		transition: all .3s;

		&:hover {
			opacity: $opacity;
		}

		@include mediaquery(){
			width: 100%;
			max-width: 335px;
		}

		// icon-cart
		.icon-cart {
			vertical-align: top;
			position: absolute;
			right: 20px;
		}
	}

	&.btn--viewmore{
		font-family: $fontfamily2;
		font-weight: bold;
		color: #fff;
		border: 1px solid #ccc;
		border-radius: 0;
		background-color: transparent;
		box-sizing: border-box;
		padding-top: 12.5px;
		padding-bottom: 12.5px;
		width: 330px;

		&:hover {
			opacity: $opacity;
		}

		@include mediaquery(){
			width: 100%;
			max-width: 330px;
		}
	}

	&.btn--support {
		font-size: 1.5rem;
		font-weight: bold;
		color: #fff;
		border: 1px solid #fff;
		border-radius: 0;
		background-color: transparent;
		box-sizing: border-box;
		position: relative;
		padding-top: 25.5px;
		padding-bottom: 25.5px;
		width: 280px;

		@include mediaquery(){
			width: 100%;
		}

		&::after{
			border: 1px solid #aaa;
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: 2px;
			left: 2px;
		}

		&:hover {
			opacity: $opacity;
		}

		.icon {
			vertical-align: top;
			position: absolute;
			left: 20px;
		}
	}
}
