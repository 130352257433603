@charset "utf-8";

/*-----debug-----*/
.pageContent {
	.pageContent__inner {
		// background-color: #ccc;
	}
}

.pageFooter {
	.pageFooter__inner {
		// background: #ccc;
	}
}

.debugHeading {
	background-color: #fc3;
	margin: $base_margin * 2 0;
	padding: $base_margin;

	&:first-child {
		margin-top: 0;
	}
}

.debugHeading2 {
	@extend .debugHeading;
	background-color: #ff6;
}

.debugImgBlock {
	color: #fff;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	background-color: #ccc;
	display: inline-block;
}

/*--------------------structure setting---------------------*/
/*----------html----------*/
html {
	&.no-js {
	}
	&.lt-ie9 {
	}
	//IE9未満
	&.lt-ie8 {
	}
	//IE8未満
	&.lt-ie7 {
	}
	//IE7未満
}

/*----------body----------*/
body {
	// ナビゲーションがアクティブな時
	&.js-nav-expanded {
		overflow: hidden;
		height: 100vh;
	}
}

/*----------pageWrapper----------*/
.pageWrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}


/*----------pageHeader----------*/
.pageHeader {
	background-color: #000;

	@include mediaquery(){
		width: 100%;
	}
	// トップページの場合
	.tpl--top & {
		@include mediaquery(){
			background-color: transparent;
			position: absolute;
		}
	}

	.pageHeader__inner {
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 auto;
		padding: 24px 30px;
		max-width: $pc_max-width;

		@include mediaquery(){
			position: relative;
			padding-right: 20px;
			padding-left: 20px;
		}

		// cool-lightboxがアクティブな場合
		body.js-cool-lightbox-active & {
			@include mediaquery(){
				z-index: 1;
			}
		}
	}

	.pageHeader__logo {
		flex-basis: 25%;

		@include mediaquery(){
			flex-basis: auto;
			z-index: 101;
		}

		.tpl--top & {
			@include mediaquery(){
				display: none;
			}
		}

		a{
			text-decoration: none;
			display: block;
			width: 97px;

			&:hover {
				opacity: $opacity;
			}
		}
	}

	.pageHeader__navToggle {
		border: none;
		background-color: transparent;
		display: none;
		padding: 0;
		width: 25px;
		height: 25px;

		@include mediaquery(){
			display: block;
			position: relative;
			z-index: 101;
		}

		&:focus{
			outline: none;
		}

		span{
			background-color: #fff;
			display: block;
			position: absolute;
			right: 0;
			left: 0;
			margin: 0 auto;
			width: 22px;
			height: 3px;

			&:nth-child(1){
				top: 3px;
			}
			&:nth-child(2){
				top: 11px;
			}
			&:nth-child(3){
				bottom: 3px;
			}
		}

		//
		.js-nav-expanded &{
			span {
				top: 0;
				bottom: 0;
				margin: auto;
				&:nth-child(1){
					transform: rotate(45deg);
				}
				&:nth-child(2){
					display: none;
				}
				&:nth-child(3){
					transform: rotate(-45deg);
				}
			}
		}
	}

	.globalNav{
		flex-basis: 50%;

		@include mediaquery(){
			background-color: #000;
			flex-basis: auto;
			// display: none;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 100;
			width: 100vw;
			height: 100vh;
		}

		ul{
			display: flex;
			justify-content: center;

			@include mediaquery(){
				flex-direction: column;
				align-items: center;
				height: 100%;
			}

			li{
				font-family: $fontfamily2;
				font-size: 1.5rem;
				font-weight: bold;
				line-height: (19 / 15);
				white-space: nowrap;
				margin: 0 1.25em;

				@include mediaquery(){
					font-size: 2.2rem;

					& + li {
						margin-top: (58px - 3.3);
					}
				}

				a{
					color: #fff;
					text-decoration: none;

					&:hover {
						opacity: $opacity;
					}
				}
			}
		}
	}

	.pageHeader__userMenu {
		flex-basis: 25%;
		display: flex;
		flex-direction: row-reverse;

		@include mediaquery(){
			flex-basis: 25px;
			position: relative;
			z-index: 101;
		}

		li {
			+ li {
				margin-right: 1.25em;
			}

			a {
				color: #fff;

				&:hover {
					opacity: $opacity;
				}
			}

			//
			.icon-lg-w {
				vertical-align: top;
			}
		}
	}
}



/*----------pageContent----------*/
.pageContent {
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	@include mediaquery{}

	.pageContent__inner {
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		@include mediaquery(){}
	}
}


/*----------pageFooter----------*/
.pageFooter {
	background-color: #333;
	margin-top: auto;

	.pageFooter__inner {
		padding: (40px - 5.5) 0 (45px - 4.5);
		margin: 0 auto;
		max-width: $pc_max-width;

		@include mediaquery(){
			padding-bottom: (30px - 4.5);
		}
	}

	.pageFooter__menu {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: (60px - 5.5);

		@include mediaquery(){
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: (40px - 5.5);
		}

		li {
			font-size: 1.3rem;
			margin: 0 2em;

			a {
				color: #fff;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}

			@include mediaquery(){
				+ li {
					margin-top: 1.455em;
				}
			}
		}
	}

	.pageFooter__logo {
		margin: 0 auto (30px - 4.5);
		width: 104px;

		a {
			&:hover {
				opacity: $opacity;
			}
		}

		@include mediaquery(){
			margin-bottom: (40px - 4.5);
		}
	}

	.copyright {
		font-size: 1.1rem;
		color: #c4c4c4;
		text-align: center;
		display: block;

		@include mediaquery(){}
	}
}
