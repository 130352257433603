/*
 * icons
 * https://shop-pro.jp/?mode=tmpl_framework
 */
.icon-user { background-position: -208px 0px; }
.icon-lg-b.icon-user { background-position: -312px 0px; }
.icon-adduser { background-position: -240px 0px; }
.icon-lg-b.icon-adduser { background-position: -360px 0px; }
.icon-login { background-position: -48px -80px; }
.icon-lg-b.icon-login { background-position: -72px -120px; }
.icon-logout { background-position: -32px -80px; }
.icon-lg-b.icon-logout { background-position: -48px -120px; }
.icon-home { background-position: -176px -16px; }
.icon-lg-b.icon-home { background-position: -264px -24px; }
.icon-mail { background-position: -64px 0px; }
.icon-lg-b.icon-mail { background-position: -96px 0px; }
.icon-pencil { background-position: -96px 0px; }
.icon-lg-b.icon-pencil { background-position: -144px 0px; }
.icon-help { background-position: -272px -80px; }
.icon-lg-b.icon-help,
.icon-lg-w.icon-help { background-position: -408px -120px; }
.icon-lg-b.icon-circled_help,
.icon-lg-w.icon-circled_help { background-position: -456px -120px;}
.icon-cart { background-position: -176px -64px; }
.icon-lg-b.icon-cart,
.icon-lg-w.icon-cart { background-position: -264px -96px; }
.icon-search { background-position: -208px -16px; }
.icon-lg-b.icon-search { background-position: -312px -24px; }
.icon-chevron_up { background-position: -0px -144px; }
.icon-lg-b.icon-chevron_up { background-position: -0px -216px; }
.icon-chevron_down { background-position: -352px -128px; }
.icon-lg-b.icon-chevron_down { background-position: -528px -192px; }
.icon-lg-b.icon-list,
.icon-lg-w.icon-list { background-position: -288px -144px; }
.icon-lg-b.icon-tools,
.icon-lg-w.icon-tools { background-position: -480px -24px;}
.icon-lg-b.icon-download,
.icon-lg-w.icon-download { background-position: -48px -168px;}

.icon-b,
.icon-w {
  width: 16px;
  height: 16px;
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
.icon-lg-b,
.icon-lg-w {
  width: 24px;
  height: 24px;
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

.icon-b { background-image: url(https://img.shop-pro.jp/tmpl_img/73/icon16_b.png); }
.icon-w { background-image: url(https://img.shop-pro.jp/tmpl_img/73/icon16_w.png); }
.icon-lg-b { background-image: url(https://img.shop-pro.jp/tmpl_img/73/icon24_b.png); }
.icon-lg-w { background-image: url(https://img.shop-pro.jp/tmpl_img/73/icon24_w.png); }

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .icon-b,
  .icon-w {
    -webkit-background-size: 368px 320px;
    background-size: 368px 320px;
  }

  .icon-lg-b,
  .icon-lg-w {
    -webkit-background-size: 552px 480px;
    background-size: 552px 480px;
  }

  .icon-b { background-image: url(https://img.shop-pro.jp/tmpl_img/73/icon16_2x_b.png); }
  .icon-w { background-image: url(https://img.shop-pro.jp/tmpl_img/73/icon16_2x_w.png); }
  .icon-lg-b { background-image: url(https://img.shop-pro.jp/tmpl_img/73/icon24_2x_b.png); }
  .icon-lg-w { background-image: url(https://img.shop-pro.jp/tmpl_img/73/icon24_2x_w.png); }
}
@media (max-width: 768px) {
  .icon-b { background-image: url(https://img.shop-pro.jp/tmpl_img/73/icon24_b.png); }
  .icon-w { background-image: url(https://img.shop-pro.jp/tmpl_img/73/icon24_w.png); }
}
@media only screen and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 2), only screen and (max-width: 768px) and (min--moz-device-pixel-ratio: 2), only screen and (max-width: 768px) and (-o-min-device-pixel-ratio: 2 / 1), only screen and (max-width: 768px) and (min-device-pixel-ratio: 2), only screen and (max-width: 768px) and (min-resolution: 192dpi), only screen and (max-width: 768px) and (min-resolution: 2dppx) {
  .icon-b { background-image: url(https://img.shop-pro.jp/tmpl_img/73/icon24_2x_b.png); }
  .icon-w { background-image: url(https://img.shop-pro.jp/tmpl_img/73/icon24_2x_w.png); }
}
