.qaList {
	padding: 0 32px;

	@include mediaquery(){
		padding: 0 vwCalc(20, 375);
	}

	&:not(:last-child){
		margin-bottom: 24.5px;
	}

	> li {
		&:not(:first-child){
			margin-top: 19px;
		}
	}

	// accrodion
	.accordion{
		.accordion__header {
			font-family: $fontfamily2;
			font-size: 1.5rem;
			color: #fff;

			margin: 0;
			padding-left: 35px;
			cursor: pointer;
			transition: all .3s;

			&:hover{
				opacity: $opacity;
			}
		}
		.accordion__content {
			margin-top: 9px;
			padding-left: 35px;
		}

		&.accordion--open .accordion__header{
			background: url(../images/icon-minus.svg) 0 50% no-repeat;
		}
		&.accordion--close .accordion__header{
			background: url(../images/icon-plus.svg) 0 50% no-repeat;
		}
	}
}

.slide {
	&-enter-active,
	&-leave-active {
		transition: height .3s ease-in-out;
		animation-fill-mode: both;
	}
	&-enter-active {
		animation-duration: 1s;
		animation-name: slide-anime-opend;
	}
	&-leave-active {
		animation-duration: .3s;
		animation-name: slide-anime-closed;
	}

	&-enter,
	&-leave-to {}
}
@keyframes slide-anime-opend {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
   }
}
@keyframes slide-anime-closed {
  0% {
   opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
