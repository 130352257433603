.featureList{
	display: flex;
	flex-wrap: wrap;
	margin-top: -30px;
	padding: 0 32px;

	@include mediaquery(){
		justify-content: space-between;
		// margin-top: -34.5px;
		margin-top: -20px;
		padding: 0 vwCalc(20, 375);
	}

	li{
		margin-top: 30px;
		// width: (183 / 900) * 100%;
		width: (280 / 900) * 100%;

		// &:not(:nth-child(4n + 1)){
		// 	margin-left: (56 / 900) * 100%;
		// }
		&:not(:nth-child(3n + 1)){
			margin-left: (30 / 900) * 100%;
		}

		@include mediaquery(){
			// margin-top: 34.5px;
			margin-top: 20px;
			width: (160 / 335) * 100%;

			// &:not(:nth-child(4n + 1)){
			// 	margin-left: 0;
			// }
			&:not(:nth-child(3n + 1)){
				margin-left: 0;
			}
		}

		a {
			text-decoration: none;
			display: block;

			&:hover{
				color: $color;
				opacity: $opacity;
			}
		}
	}

	.featureList__thumb {
		margin-bottom: 4.5px;

		@include mediaquery(){
			margin-bottom: 10px;
		}
	}

	.featureList__name {

	}
}
