.importer {
	@include clearfix();
	padding: 0 32px;

	@include mediaquery(){
		padding: 0 vwCalc(20, 375);
	}

	.importer__heading {
		font-family: $fontfamily2;
		font-size: 1.6rem;
		font-weight: normal;
		line-height: (24 / 16);
		color: #fff;
		float: left;
		margin-right: 60px;

		@include mediaquery(){
			float: none;
			margin-right: 0;
			margin-bottom: 11px;
		}
	}
	.importer__summary {
		overflow: hidden;
	}

	.importer__name {
		font-size: 1.6rem;
		font-weight: bold;
		line-height: (24 / 16);
		color: #fff;
		margin: 0;
	}
	.importer__address {
		margin: 11px 0 0;
	}
	.importer__phone {
		margin: 11px 0 0;
	}
}
