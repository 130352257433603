.supportLink{
	display: flex;
	flex-wrap: wrap;
	margin-top: -20px;
	padding: 0 32px;

	@include mediaquery(){
		flex-direction: column;
		align-items: center;
		padding: 0 vwCalc(20, 375);
	}

	li{
		margin-top: 20px;

		@include mediaquery(){
			width: 100%;
			max-width: 335px;
		}

		&:not(:last-child) {
			margin-right: 30px;

			@include mediaquery(){
				margin-right: 0;
			}
		}
	}
}
