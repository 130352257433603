.icon {

	&.icon--pdf {
		vertical-align: top;
		content: url(../images/icon-metro-file-pdf.svg);
	}
	&.icon--help {
		vertical-align: top;
		content: url(../images/icon-help.svg);
	}
	&.icon--tools {
		vertical-align: top;
		content: url(../images/icon-tools.svg);
	}
	&.icon--download {
		vertical-align: top;
		content: url(../images/icon-download.svg);
	}
}
